import React, { useState } from 'react';
import { styled } from '@mui/system';
import axios from "../../projectAxios";

import {
    Card,
    CardHeader,
    Button,
    TextField,
    FormControl,
    Typography,
    Stack
  } from "@mui/material";


  const WhiteTextTypography = styled(Typography)({
    root: {
      color: "#AFE1AF"
    }
  });

const GetUserPrice = ({token}) =>
{
    const [submitMasage, setSubmitMasage] = useState(null);
    const [successMassage, setSuccessMassage] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const [formError, setFormError] = useState(false);

    const [resp, setResp] = useState("");

    const [userPricePerMonth, setUserPricePerMonth] = useState({
        userId: "",
        year: "",
        month: "",
      });


    const handleChangeOfuserPricePerMonth = (event) => {
        const { name, value } = event.target;
  
        if (name === "userId") {
          // Define a regular expression pattern for a 32-character hexadecimal string
          const userIdPattern = /^[0-9a-fA-F]{32}$/;
          const isUserIdValid = userIdPattern.test(value);
          if (value === "")
          {
            setEmailError(false);
          }
          else{
            setEmailError(!isUserIdValid);
          }
        }
  
        setUserPricePerMonth({
          ...userPricePerMonth,
          [name]: value,
        });
      };


    const validateForm = () => {
        // Check if any field is empty
        for (const key in userPricePerMonth) {
            if (userPricePerMonth[key] === "") {
            setFormError(true);
            return false;
            }
        }
        // Check if the email format is valid
        if (emailError) {
            setFormError(true);
            return false;
        }

        setFormError(false);
        return true;
    };

      
    const handleGetUserPricing = () => {
        setSuccessMassage(null);
        setSubmitMasage(null);
  
        if (!validateForm()) {
          setSubmitMasage("Not a valid form");
          // Form validation failed, don't proceed with user creation
          return;
        }
        
        const params = {
            uuid: userPricePerMonth.userId, 
            yearMonth : `${userPricePerMonth.year}_${userPricePerMonth.month}`
        };

        axios
        .get(`/MovieUpload/Video/GetStoredBytes?uuid=${params.uuid}&yearMonth=${params.yearMonth}`, { headers: {Authenticate: token, usergetstoragebytes: "vad2149f68"}})
        .then(resp => {
            setSubmitMasage("");
            setResp(`Total stored bytes for ${userPricePerMonth.month}/${userPricePerMonth.year} is: ${resp.data.totalBytesStored}`);
        })
        .catch(err => {
            setResp("");
            if (err.response && err.response.status) {
                setSubmitMasage("Error in sending the form: " + err.response.status);
            } else {
                setSubmitMasage("An error occurred while sending the form.");
            }
        });
      };

    return (<Card>
        <CardHeader title={"Get User Storage Bytes By UUID"} style={{color:"#757575"}}/>
        <FormControl style={{width:"100%"}}> 
          <form>
            <Stack spacing={2} margin={5}>
              <TextField
                name="userId"
                label="User Id"
                variant="outlined"
                error={emailError}
                helperText={emailError ? "Invalid UUID format" : null}
                onChange={handleChangeOfuserPricePerMonth}
              />
              <TextField
                name="year"
                label="Year"
                variant="outlined"
                onChange={handleChangeOfuserPricePerMonth}
              />
              <TextField
                name="month"
                label="Month"
                variant="outlined"
                onChange={handleChangeOfuserPricePerMonth}
              />
              
              <Button
                variant="contained"
                color="primary"
                onClick={handleGetUserPricing}
                fullWidth
              >
                Get Storage Bytes
              </Button>
              {formError && (
                <Typography color="error" align="center">
                  Please fill out all fields correctly.
                </Typography>
              )}
              {submitMasage && (
                <Typography color="error" align="center">
                  {submitMasage}
                </Typography>
              )}
              {successMassage && (
                <WhiteTextTypography align="center">
                  {successMassage}
                </WhiteTextTypography>
              )}
              { resp !== "" && ( <WhiteTextTypography align="center" sx={{ color: "#007500" }}>
                  {resp}
                </WhiteTextTypography>)}
            </Stack>
          </form>
        </FormControl>
      </Card>);
}


export default GetUserPrice;